@import 'assets/styles/index.scss';

.AntdModal--fullscreen {
  max-width: unset;
  margin: unset;
  top: unset;

  .ant-modal, .ant-modal-content {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
  }

  .ant-modal-header {
    height: 80px;
  }

  .ant-modal-body {
    height: calc(100vh - 150px);
  }

  .ant-modal-centered::before {
    content: unset;
  }
}