@import 'assets/styles/index.scss';

.DragSortingList tr.ant-table-row.drop-over-downward > td {
  border-bottom: 2px dashed #1890ff !important;
}

.DragSortingList tr.ant-table-row.drop-over-upward > td {
  border-top: 2px dashed #1890ff !important;
}

.DragSortingList .move-in-empty-table {
  background-color: #1890ff;
  opacity: 0.2;
}

.DragSortingList .ant-table-content {
  padding: 0 !important;
}

.DragSortingList tr.ant-table-row > td {
  vertical-align: middle !important;
}

.DragSortingList thead.ant-table-thead {
  height: 50px;
}

.DragSortingList thead.ant-table-thead > tr > th {
  color: $default-dark;
  font-weight: 600;
}
