@import 'assets/styles/index.scss';

.Input.is-plain {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  background-color: transparent !important;
  box-shadow: none;
}

.Input .ant-select-selector {
  padding-left: 0 !important;
}

.Input.is-plain:focus {
  box-shadow: none;
}