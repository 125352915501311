// ---------------------------------------------------------
// @Greyscale Colors
// ---------------------------------------------------------

$grey-100             : #f9fafb;
$grey-200             : #f2f3f5;
$grey-300             : #e6eaf0;
$grey-400             : #d3d9e3;
$grey-500             : #b9c2d0;
$grey-600             : #7c8695;
$grey-700             : #72777a;
$grey-800             : #565a5c;
$grey-900             : #313435;

// ---------------------------------------------------------
// @Bootstrap Color System
// ---------------------------------------------------------

$blue                 : #2196f3;
$indigo               : #3f51b5;
$purple               : #9c27b0;
$pink                 : #e91e63;
$red                  : #f44336;
$orange               : #ff9800;
$yellow               : #ffeb3b;
$green                : #4caf50;
$teal                 : #009688;
$cyan                 : #00bcd4;

// ---------------------------------------------------------
// @Default Colors
// ---------------------------------------------------------

$default-danger       : #8c1a11;
$default-dark         : #313435;
$default-grey         : #565a5c;
$default-info         : #0f9aee;
$default-primary      : $blue;
$default-success      : #00875a;
$default-text-color   : #72777a;
$default-warning      : #efb636;
$default-white        : #fff;

// ---------------------------------------------------------
// @Tinted Colors
// ---------------------------------------------------------

$tinted-cyan          : #f0fbfc;
$tinted-yellow        : #fffef3;
$tinted-green         : #f4faf5;
$tinted-grey          : #f2f2f2;
$tinted-red           : #f4e8e7;

// ---------------------------------------------------------
// @Inverted Colors
// ---------------------------------------------------------

$inverse-danger       : lighten($default-danger, 30%);
$inverse-info         : lighten($default-info, 30%);
$inverse-primary      : lighten($default-primary, 30%);
$inverse-success      : lighten($default-success, 30%);
$inverse-warning      : lighten($default-warning, 30%);
$inverse-dark         : lighten($default-dark, 30%);

// ---------------------------------------------------------
// @Badges
// ---------------------------------------------------------

$badge-todo           : #0f9aee;
$badge-progress       : #efb636;
$badge-resolved       : #00875a;
$badge-problem        : #d6494a;
$badge-closed         : #c4d8de;

// ---------------------------------------------------------
// @Others
// ---------------------------------------------------------

$border-color         : rgba(0,0,0,.0625);
$side-nav-dark        : #313644;
$side-nav-dark-border : rgba(120, 130, 140, 0.3);
$side-nav-dark-font   : #99abb4;
$tab-grey             : #f2f2f2;
$required-color       : #FC7676;

// ---------------------------------------------------------
// @AntD Colours
// ---------------------------------------------------------
$ant-text-color       : rgba(0, 0, 0, 0.65);
$ant-disabled-color   : rgba(0, 0, 0, 0.35);
$ant-grey-color       : rgba(0, 0, 0, 0.45);
$ant-border-color     :#d9d9d9;
$ant-danger           :#ff4d4f;
$ant-blue             :#1890ff;

// ---------------------------------------------------------
// @Header Themes
// ---------------------------------------------------------

$theme-danger         : #f53f61;
$theme-dark           : lighten($side-nav-dark, 10%);
$theme-info           : $default-info;
$theme-primary        : $default-primary;
$theme-success        : desaturate($default-success, 5%);
