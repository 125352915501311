@import 'assets/styles/index.scss';

.CostField {
  & .ActionButton {
    padding: 4px 7px;
    width: 32px;
  }
}

.border-problem {
  border-color: $ant-danger !important;
}