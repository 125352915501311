// ---------------------------------------------------------
// @Animations
// ---------------------------------------------------------

:root {
  --color-blue: $blue;
}

/** Bounce in
========================================================================== */

.u-animationBounceIn {
  animation: bounceIn 0.3s both;
}
  
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  50% {
    opacity: 1;
    transform: scale(1.004);
  }

  100% {
    transform: scale(1);
  }
}

/** Bounce out
========================================================================== */

.u-animationBounceOut {
  animation: bounceOut 0.3s both;
}

@keyframes bounceOut {
  100% {
    opacity: 0;
    transform: scale(0.7);
  }

  50% {
    opacity: 1;
    transform: scale(1.004);
  }

  0% {
    transform: scale(1);
  }
}

/** Scale highlight
========================================================================== */

.u-animationScaleHighlight {
  animation: scaleHighlight 0.3s both;
}

@keyframes scaleHighlight {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.015);
  }

  100% {
    transform: scale(1);
  }
}

/** Fade in
========================================================================== */

.u-fadeIn {
  animation: fadeIn 0.7s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/** Fade out
========================================================================== */

.u-fadeOut {
  animation: fadeOut 0.7s ease 0s 1 normal forwards running;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/** Rotate 360
========================================================================== */

.u-animationRotate360 {
  animation: rotate360 4s;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/** Transition 90
========================================================================== */

.u-transitionRotate90 {
  transition: 0.2s;
  transform: rotate(90deg);
}

/** Rotate
========================================================================== */

.u-animationBounceDelay {
  animation: bounceDelay 1.4s infinite ease-in-out both;
}

@keyframes bounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

/** Slide in
========================================================================== */

.u-animationSlideIn {
  animation: slideIn 0.3s both;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

/** Slide in light
========================================================================== */

.u-animationSlideInLight {
  animation: slideInLight 0.3s both;
}

@keyframes slideInLight {
    0% {
      opacity: 0;
      transform: translateY(40%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
}

/** Pulsating border
========================================================================== */

.u-animationPulsatingBorder {
  animation: pulsatingBorder 0.3s both;
}

@keyframes pulsatingBorder {
  0% {
    box-shadow: 0 0 10px color(var(--color-blue) alpha(40%)), inset 0 0 5px color(var(--color-blue) alpha(20%));
    border-color: var(--color-blue);
  }

  50% {
    box-shadow: 0 0 2px color(var(--color-blue) alpha(30%)), inset 0 0 2px color(var(--color-blue) alpha(20%));
    border-color: color($inverse-primary);
  }

  100% {
    box-shadow: 0 0 10px color(var(--color-blue) alpha(40%)), inset 0 0 5px color(var(--color-blue) alpha(20%));
    border-color: var(--color-blue);
  }
}

/** Wobble
========================================================================== */

.u-animationWobble {
  animation: wobble 1s;
}

@keyframes wobble {
    0% {
      transform: none;
    }

    15% {
      transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
      transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
      transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
      transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
      transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    100% {
      transform: none;
    }
}

/** SwatchBounce
========================================================================== */

.u-animationSwatchBounce {
  animation: swatchBounce 0.3s;
}

@keyframes swatchBounce {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7), 0 0 0 0 currentcolor;
    }

    50% {
      transform: scale(1.05);
    }

    70% {
      box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.7), 0 0 0 5px currentcolor;
    }

    100% {
      transform: scale(1);
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.7), 0 0 0 4px currentcolor;
    }
}

/** SlideDown
========================================================================== */

.u-animationClone {
  position: relative;
  z-index: 1;
  animation: clone 1s;
}

@keyframes clone {
    0% {
      transform: scale(1) translateY(-100%);
      box-shadow: 0 10px 30px 3px rgba(0, 0, 0, 0.15);
      opacity: 0;
      outline: 2px solid color(var(--color-blue) alpha(80%));
      outline-offset: 2px;
    }

    7% {
      opacity: 1;
    }

    27% {
      transform: scale(1.07);
      box-shadow: 0 20px 50px 5px rgba(0, 0, 0, 0.15);
    }

    35% {
      transform: scale(0.99);
    }

    50% {
      transform: scale(1) translateY(0);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }

    80% {
      outline: 2px solid color(var(--color-blue) alpha(80%));
      outline-offset: 2px;
    }

    100% {
      outline-color: transparent;
    }
}

/** Pulse
========================================================================== */

.u-animationPulse {
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba($default-primary, .5);
  animation: pulse 1.5s normal;
}

@keyframes pulse {
  70% {
    box-shadow: 0 0 0 20px rgba($default-primary, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($default-primary, 0);
  }
}

.u-animationPulseBlue {
	box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
	animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
	0% {
		box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
	}
	
	70% {
		box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
	}
	
	100% {
		box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
	}
}