@import 'assets/styles/index.scss';

.Field {
  width: 100%;

  &.ant-input-number {
    width: 100%;
  }

  &--has-warning {
    &:focus {
      box-shadow: 0 0 0 2px rgba($default-warning, 0.2) !important;
    }
  }

  &--has-error {
    &:focus {
      box-shadow: 0 0 0 2px rgba($default-danger, 0.2) !important;
    }
  }
}

.Field-Error {
  &-Icon {
    cursor: pointer;
    color: $default-text-color !important;
    padding: 5px 10px;

    svg {
      height: 20px;
      width: 20px;

      path {
        fill: $default-text-color !important;
      }
    }
  }
}

.Field-Warning {
  .ant-tooltip-content {
    .ant-tooltip-arrow-content {
      background-color: $default-warning;
    }

    .ant-tooltip-inner {
      background-color: $default-warning;
      color: $ant-text-color;
    }
  }

  &-Icon {
    padding: 5px 10px;

    svg {
      height: 20px;
      width: 20px;

      path {
        fill: $default-warning !important;
      }
    }
  }
}

.Field-Number {
  .ant-input-number-input {
    text-align: right;
  }
}

.Select-Field {
  .ant-select-selector {
    width: 100%;
  }

  &--has-warning {
    &.ant-select-focused {
      box-shadow: 0 0 0 2px rgba($default-warning, 0.2) !important;
    }

    .ant-select-selector {
      border: 1px solid $default-warning !important;

      &:focus {
        box-shadow: 0 0 0 2px rgba($default-warning, 0.2) !important;
      }

      .ant-input-affix-wrapper {
        border: none !important;
      }
    }
  }

  &--has-error {
    &.ant-select-focused {
      box-shadow: 0 0 0 2px rgba($default-danger, 0.2) !important;
    }

    .ant-select-selector {
      border: 1px solid $default-danger !important;

      &:focus {
        box-shadow: 0 0 0 2px rgba($default-danger, 0.2) !important;
      }

      .ant-input-affix-wrapper {
        border: none !important;
      }
    }
  }
}