@import 'assets/styles/index.scss';

.ContactField {
  cursor: pointer;

  .ant-table-cell-with-append {
    display: flex;
    min-height: 75px;

    .ContactField-Name {
      height: 30px;
      margin: 5px;
    }
  }

  .ant-table-row-expand-icon {
    padding: 7px !important;
    margin-top: 10px !important;
  }
} 