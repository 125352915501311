@import 'assets/styles/index.scss';

.BlockingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.BlockingSpinner-spinner {
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  box-sizing: border-box;
  border-top: 2px solid $blue;
  border-right: 2px solid transparent;
  animation: spinner .6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}