@import 'assets/styles/index.scss';

.ant-table-wrapper .ant-table-thead th {
  color: $default-grey;
  background-color: #FFF;
}

.AdvancedList .ant-table-row.ant-table-row-level-0 .ant-table-cell {
  background-color: #FFF;
}

.AdvancedList .ant-table-row.ant-table-row-level-1 .ant-table-cell {
  background-color: #FAFAFA;
}

.AdvancedList .ant-table-row.ant-table-row-level-2 .ant-table-cell {
  background-color: #F7F7F7;
}