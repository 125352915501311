@import 'assets/styles/index.scss';

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 13;
}

.Modal--lightBackdrop {
  background-color: rgba(100, 100, 110, 0.2);
}

.Modal-container {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25), 0 0 0 1px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;
}

.Modal-navigation {
  position: relative;
  display: flex;
  font-weight: 500;
  font-size: 18px;
  color: #333;
  white-space: nowrap;
  width: 100%;
}

.Modal-navigation-side {
  flex: 0 1 50%;
}

.Modal-navigation-middle {
  flex: 1 0 auto;
  cursor: default;
  min-height: 60px;
  letter-spacing: -0.02em;
  text-align: center;
}

.Modal-navigation-close {
  display: inline-block;
  cursor: pointer;
  margin-left: 25px;
  margin-right: 35px;
  width: 42px;
  height: 42px;
  background: #e9e8e3;
  border-radius: 50%;
  vertical-align: middle;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAdtJREFUWAnNmG1KAzEQhndbj6PWYo+jiDcRFDxAoYJ/IggeRwt6HtdnqtOm+5HNJhNwYMjHTN55uk3TNlX1z6z2eZqmuWC8wD/run73Y9Z9aq3QPMM/qLXt6JPwiPv2xGDWScycQHOOP/uF6N8fyTKxbCXo8JWOGRRaAvOm4l77TV+e1q8xuPWC7a4JFKJDMFrvSnkqZi51dqDNgkJzDEbKyt49GBOyZ0KWBIVgDMz6QPLXY+EMl6IhmwSFUAyMI69/n0oAN4FCJw9GH5kFlBmMBZQ5TA5UMZgUqOIwCVB9JzCce3P0+j9NWiy2FSF87NNHyqA5IjYwCi2CeAqUPUwGVDmYBKgkmJT3VX7UzRUw0J4EYjYh9lDM1wFpe5M9l/Kix4ERngqjVPZQGTD2UJEwjjx5EiHLf1Kox7xNL+TJGRVzTqVDTYHRHVgMKgWmGFQOjDmUBYwZlCWMCRRA7b+3TB2ZYzT51JU1+NiRsNEXsWtZsMJD5ghOhtEishYfg1pqvvxzvcGHzBFIhtEiooGHoK41V4AWeJ85JrNhtJBo4X1Qctlwqnm7lokHXAJqazpmMFpMNPGNFqGVmncab19YnRPQC6vuJZKuMmiBkMsxeSpbLqy+DCTLSPwAYfqpUGoqpu4AAAAASUVORK5CYII=");
}

.Modal-close-align-top {
  position: absolute;
  top: 10px;
  right: -20px;
}

.Modal-navigation-close:hover {
  background-color: darken(#e9e8e3, 10);
}

.Modal--cover {
  background-color: transparent;
}

.Modal--cover > .Modal-container {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  animation-duration: 0.4s;
  animation-fill-mode: backwards;
}

.Modal--dialog > .Modal-container {
  border-radius: 5px;
  animation-duration: 0.4s;
}

@include to($breakpoint-md) {
  .Modal-container {
    width: 90vw;
    min-width: 90vw;
  }
}
