@import "assets/styles/index.scss";

.EntityRolesTab-Table-Add-Button-Icon {
  font-size: 16px;
  line-height: 10px;
}

.EntityRolesTab-Table-DragMenu {
  cursor: grab;
  color: $default-grey !important;
}