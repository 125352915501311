@import 'assets/styles/index.scss';

.Dropdown .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: rgb(217, 217, 217);
}

.Dropdown .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]):hover {
  border-left-color: inherit;
}

// Hide default button
.Dropdown.Dropdown--hide-default-button .ant-dropdown-button > :first-child {
  display: none;
}