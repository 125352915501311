@import 'assets/styles/index.scss';

.WorkflowMesh {
  position: relative;
  flex-grow: 1;
  height: 100%;
}

.WorkflowMesh .react-flow__controls-button {
  box-sizing: content-box;
}

.CustomNode {
  border: 1px solid $grey-500;
  padding: 10px;
  width: 150px;
  border-radius: 3px;
  background: $default-white;
}

.CustomNode--is-current {
  background: $default-info;
}

.CustomNode-title {
  font-size: 12px;
  color: $default-dark;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.CustomNode-title--is-current {
  color: $default-white;
}

.CustomNode-source {
  background: $grey-800 !important;
}

.CustomNode-target {
  background: $red !important;
}

.ConditionsTableWrapper {
  max-height: 50vh;
  overflow-y: auto;
}