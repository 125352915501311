@import 'assets/styles/index.scss';

.Comment {
  color: $ant-text-color;
}

.Comment >.ant-comment {
  background-color: $default-white;
  border: 1px solid $border-color;
  border-radius: 2px;
  color: $ant-text-color;
  margin-bottom: 10px;
  padding: 20px;
}