// ---------------------------------------------------------
// @List Style Position
// ---------------------------------------------------------

.lisp-i {
  list-style-position: inside !important;
}

.lisp-o {
  list-style-position: outside !important;
}

// ---------------------------------------------------------
// @List Style Type
// ---------------------------------------------------------

.lis-n {
  list-style: none !important;
}

.list-c {
  list-style-type: circle !important;
}

.list-s {
  list-style-type: square !important;
}

.list-u {
  list-style-type: upper-roman !important;
}

.list-l {
  list-style-type: lower-alpha !important;
}

.list-h {
  list-style-type: '- ' !important;
}