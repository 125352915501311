.DayTimePicker-outer-wrapper {
  display: flex;
  height: max-content;
}

.DayTimePicker-main-wrapper {
  display: flex;
  height: max-content;
  z-index: 2;
}

.DayTimePicker-dropdown-wrapper {
  padding: 0;
  opacity: 0;
  height: 0;
  width: max-content;
  pointer-events: none;
  width: max-content;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #FFFFFF;
}

.DayTimePicker-dropdown-wrapper.DayTimePicker-dropdown-active {
  height: max-content;
  padding: 12px;
  opacity: 1;
  pointer-events: all;
}

.DayTimePicker-options-container {
  display: flex;
  align-items:flex-start;
  padding: 0 8px;
  padding-bottom: 12px;
}

.DayTimePicker-title {
  color: #565a5c;
}

.DayTimePicker-option-wrapper {
  flex: 1 1 auto;
  height: calc(56px + 9.8rem);
  overflow: auto;
}

.DayTimePicker-options {
  width: 100%;
  padding: 0 1px;
}

.DayTimePicker-option {
  padding: 4px 10px;
  font-size: 14px;
  line-height: 1.4rem;
  transition: 0.3s cubic-bezier(0, 0, 0, 0.9);
  background-color: #fff0;
  color: #000;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
}

.DayTimePicker-option-selected {
  background-color: #e6f7ff;
}

.DayTimePicker-bottom-bar-wrapper {
  padding: 0 8px;
}

.DayTimePicker-bottom-bar {
  border-top: 1px solid #D7D7D7;
}

.DayTimePicker-actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  padding-top: 12px;
}
