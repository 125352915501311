// ---------------------------------------------------------
// @Position (0 > 4 Step 1)
// ---------------------------------------------------------

@for $i from 0 through 4 {
  .t-#{$i} { top: #{$i}px !important; }
  .r-#{$i} { right: #{$i}px !important; }
  .b-#{$i} { bottom: #{$i}px !important; }
  .l-#{$i} { left: #{$i}px !important; }
}

// ---------------------------------------------------------
// @Position (5 > 35 Step 5)
// ---------------------------------------------------------

@for $i from 5 through 35 {
  @if $i % 5 == 0 {
    .t-#{$i} { top: #{$i}px !important; }
    .r-#{$i} { right: #{$i}px !important; }
    .b-#{$i} { bottom: #{$i}px !important; }
    .l-#{$i} { left: #{$i}px !important; }
  }
}

// ---------------------------------------------------------
// @Position (40 > 160 Step 10)
// ---------------------------------------------------------

@for $i from 40 through 160 {
  @if $i % 10 == 0 {
    .t-#{$i} { top: #{$i}px !important; }
    .r-#{$i} { right: #{$i}px !important; }
    .b-#{$i} { bottom: #{$i}px !important; }
    .l-#{$i} { left: #{$i}px !important; }
  }
}

// ---------------------------------------------------------
// @Position (20%)
// ---------------------------------------------------------

.t-20p { top: 20% !important; }
.r-20p { right: 20% !important; }
.b-20p { bottom: 20% !important; }
.l-20p { left: 20% !important; }

// ---------------------------------------------------------
// @Position (50%)
// ---------------------------------------------------------

.tl-50p {
  top: 50% !important;
  left: 50% !important;
}

.tr-50p {
  top: 50% !important;
  right: 50% !important;
}

.t-50p { top: 50% !important; }
.r-50p { right: 50% !important; }
.b-50p { bottom: 50% !important; }
.l-50p { left: 50% !important; }

// ---------------------------------------------------------
// @Position (80%)
// ---------------------------------------------------------

.t-80p { top: 80% !important; }
.r-80p { right: 80% !important; }
.b-80p { bottom: 80% !important; }
.l-80p { left: 80% !important; }