@import 'assets/styles/index.scss';

.Resources-Table {
  .ant-table-row {
    height: 65px;
  }

  .cell-line-height {
    line-height: 32px;
  }
}

.Resources-Table-Dual-Field {
  &:first-child {
    margin-right: 5px;
  }

  &:last-child {
    margin-left: 5px;
  }
}

.Resources-Table-Tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: inherit !important;
}

@include to($breakpoint-sm) {
  .Resources-Table-Dual-Field-Container {
    flex-direction: column;
  }

  .Resources-Table-Dual-Field {
    &:first-child {
      margin-right: 0;
      margin-bottom: 10px;
    }

    &:last-child {
      margin-left: 0;
    }
  }
}

.Resources-Table-Multi-Field {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.Resources-Table-Add-Modal {
  width: 1000px !important;
}

.Resources-Table-Add-Button-Icon {
  font-size: 16px;
  line-height: 10px;
}

.Resources-Table-Row-Button {
  padding: 4px 7px !important;
  width: 32px;
}

.Resources-Table-Row-Pending {
  .ant-table-row-expand-icon {
    border-color: $default-warning !important;
  }
}

.Resources-Table-Row-Rejected {
  .ant-table-row-expand-icon {
    border-color: $default-danger !important;
  }
}

.Resources-Table-Primary-Spacer {
  display: block;
  width: 15px;
}