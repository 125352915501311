// ---------------------------------------------------------
// @Centering
// ---------------------------------------------------------

.centerY {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.centerX {
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.centerXY {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}