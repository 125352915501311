@import 'assets/styles/index.scss';

.Notification {
  display: flex;
}

.Notification-sidebar {
  flex-shrink: 0;
  flex-basis: 30%;
  min-width: 350px;
}

.Notification-sidebar-header {
  padding: 20px 25px 17px 25px;
}

.Notification-sidebar-item {
  height: 100px;
  border-left: 3px solid transparent !important;
  user-select: none;
}

.Notification-sidebar-item-active {
  border-left: 3px solid $default-primary !important;
}

.Notification-sidebar-item:hover {
  border-left: 3px solid $grey-600 !important;
}

.Notification-content {
  flex-grow: 1;
}

.Notification-content-left-controls {
  display: none;
  margin-right: 30px;
}

.Notification-content-body {
  padding: 30px 30px;
}

.Notification-content-body a {
  color: $default-primary;
}

@include to($breakpoint-lg) {
  .Notification.Notification--open {
    .Notification-sidebar {
      display: none !important;
    }

    .Notification-content-left-controls {
      display: block;
    }

    .Notification-content-body {
      padding: 40px 90px;
    }
  }

  .Notification:not(.Notification--open) {
    .Notification-sidebar {
      flex-shrink: 0;
      flex-basis: 100%;
      max-width: 100%;
    }

    .Notification-content {
      display: none !important;
    }
  }
}