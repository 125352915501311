// ---------------------------------------------------------
// @Display
// ---------------------------------------------------------

.d-b          { display: block !important; }
.d-ib         { display: inline-block !important; }
.d-i          { display: inline !important; }
.d-tb         { display: table !important; }
.d-tbc        { display: table-cell !important; }
.d-n          { display: none !important; }
.d-f          { display: flex !important; }
.d-if         { display: inline-flex !important; }

// ---------------------------------------------------------
// @Overflow
// ---------------------------------------------------------

.ov-h         { overflow: hidden !important; }
.ov-a         { overflow: auto !important; }
.ov-s         { overflow: scroll !important; }

.ovY-h        { overflow-y: hidden !important; }
.ovX-h        { overflow-x: hidden !important; }
.ovY-a        { overflow-y: auto !important; }
.ovX-a        { overflow-x: auto !important; }
.ovY-s        { overflow-y: scroll !important; }
.ovX-s        { overflow-x: scroll !important; }

// ---------------------------------------------------------
// @Float
// ---------------------------------------------------------

.fl-l         { float: left !important; }
.fl-r         { float: right !important; }
.fl-n         { float: none !important; }

// ---------------------------------------------------------
// @Vertical Align
// ---------------------------------------------------------

.va-t         { vertical-align: top !important; }
.va-tp        { vertical-align: text-top !important; }
.va-m         { vertical-align: middle !important; }
.va-b         { vertical-align: bottom !important; }
.va-s         { vertical-align: sub !important; }

// ---------------------------------------------------------
// @Position
// ---------------------------------------------------------

.pos-s        { position: static !important; }
.pos-st       { position: sticky !important; }
.pos-r        { position: relative !important; }
.pos-a        { position: absolute !important; }
.pos-f        { position: fixed !important; }

// ---------------------------------------------------------
// @Z-Index
// ---------------------------------------------------------

@for $i from 0 through 9 {
  .z-#{$i} { z-index: ($i * 1000) !important; }
}
