@import 'assets/styles/index.scss';

.ClassificationsTable {
  & .ActionButton {
    padding: 4px 7px;
    width: 32px;
  }

  & .DragMenu {
    cursor: grab;
    color: $default-grey;
  }

  & thead.ant-table-thead > tr > th {
    font-weight: 400;
  }
}

.InfoBox {
  &__button,
  &__button.ant-btn {
    font-weight: 500;
    cursor: default;

    &--info {
      width: 32px;
      padding: 4px 0 !important;
    }

    &--with-spacing {
      margin-right: 4px;
    }
  }

  &__button {
    &--warning,
    &--warning.ant-btn-primary {
      background-color: $default-warning;
      border: 1px solid $default-warning;

      &:active,
      &:focus,
      &:hover {
        background-color: $default-warning;
        border: 1px solid $default-warning;
      }
    }

    &--with-spacing {
      margin-right: 4px;
    }

    &-Context {
      padding: 4px 7px !important;
      width: 36px;
    }
  }
}