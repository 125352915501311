@import 'assets/styles/index.scss';

.ComponentsTableWrapper {
  max-height: 50vh;
  overflow-y: auto;
}

.ComponentsTableWrapper .ant-table-content {
  padding: 0 !important;
}

.ComponentsTableWrapper .ActionButton {
  padding: 4px 7px;
  width: 32px;
}

.ComponentsTableWrapper .ConfigRow {
  padding-left: 40px;
}