@import 'assets/styles/index.scss';

.WorkflowAssignmentTable {
  & .ActionButton {
    padding: 4px 7px;
    width: 32px;
  }

  & .DragMenu {
    cursor: grab;
    color: $default-grey;
  }

  & thead.ant-table-thead > tr > th {
    font-weight: 400;
  }
}