@import 'assets/styles/index.scss';

.Layout {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.Layout-menu {
  display: flex;
  z-index: 5;
}

.Layout-container {
  flex-grow: 1;
  z-index: 1;
  height: 100%;
  overflow: hidden;
}

.Layout-content {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - (#{$header-height} + 1px));
}

.Layout-wrapper {
  height: auto;
}

.Layout-box {
  background-color: $default-white;
  border: 1px solid $border-color;
  padding: 20px 20px 10px 20px;
}

.Layout-notifications {
  display: flex;
}

.Layout-notifications-sidebar {
  min-width: 500px;
  width: 30%;
}

.Layout-notifications-content {
  flex-grow: 1;
}

@include to($breakpoint-md) {
  .Layout {
    display: block; 
  }

  .Layout-menu {
    position: fixed;
    display: block;
  }

  .Layout-container {
    display: block;
    margin-left: calc(#{$primary-sidebar-collapsed-width});
  }

  .Layout.Layout-secondary-menu-visible .Layout-container {
    margin-left: calc(#{$primary-sidebar-collapsed-width} + #{$secondary-sidebar-collapsed-width});
  }
}

@include to($breakpoint-lg) {
  .Layout-notifications.Layout-notifications--open .Layout-notifications-sidebar {
    display: none;
  }

  .Layout-notifications:not(.Layout-notifications--open) {
    .Layout-notifications-sidebar {
      min-width: inherit;
      width: 100%;
    }
    .Layout-notifications-content {
      display: none;
    }
    .Layout-notifications-left-controls {
      display: none;
    }
  }
}