@import 'assets/styles/index.scss';

.Currency-Field-Select {
  display: inline-block;
  padding-right: 5px;
  width: calc(100% / 3);
}

.Currency-Field-Value {
  display: inline-block;
  width: calc((100% / 3) * 2);
}

@include to($breakpoint-sm) {
  .Currency-Field-Select {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }

  .Currency-Field-Value {
    width: 100%;
  }
}