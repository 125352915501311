@import 'assets/styles/index.scss';

.Divider {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  color: $grey-300 !important;
}

.Divider .ant-divider-inner-text {
  color: $grey-700 !important;
}