@import 'assets/styles/index.scss';

.Switch {
  display: flex;
  align-items: center;
  color: #262626;
}

.Switch-Wrapper {
  display: flex;
  align-items: center;
  margin-left: 10px;
  border: 1px solid $ant-border-color;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffffff;
  height: 32px;
  border-radius: 2px;
}

.Switch--deselected {
  color: #bfbfbf;
}

.Switch-Todo {
  background-color: $badge-todo !important;
  border-color: $badge-todo !important;
}

.Switch-Progress {
  background-color: $badge-progress !important;
  border-color: $badge-progress !important;
}

.Switch-Resolved {
  background-color: $badge-resolved !important;
  border-color: $badge-resolved !important;
}

.Switch-Problem {
  background-color: $badge-problem !important;
  border-color: $badge-problem !important;
}

.Switch-Closed {
  background-color: $badge-closed !important;
  border-color: $badge-closed !important;
}