@import "assets/styles/index.scss";

.DynamicFieldTemplate .ant-collapse-item-disabled .ant-collapse-header {
  color: rgba(0,0,0,.85) !important;
  cursor: default !important;
}

.DynamicFieldTemplate .ant-collapse-header {
  display: flex !important;
  align-items: center !important;
}