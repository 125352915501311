@import 'assets/styles/index.scss';

.OverlaySpinner {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.55);
  height: 100vh;
  width: 100vw;
  z-index: 9999999;
}

.OverlaySpinner-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.OverlaySpinner-spinner {
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  box-sizing: border-box;
  border-top: 2px solid $blue;
  border-right: 2px solid transparent;
  animation: spinner .6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}