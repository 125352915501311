@import 'assets/styles/index.scss';

.File-Field .ant-upload-list-item {
  margin-top: 10px !important;
}

.File-Field .ant-upload-list-item-name {
  cursor: pointer;
}

.File-Field.is-disabled .ant-upload {
  cursor: not-allowed !important;
}

.File-Field.is-disabled .ant-upload.ant-upload-select-picture-card:hover {
  border-color: $default-danger !important;
}