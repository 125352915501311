// ---------------------------------------------------------
// @Flex Wrap
// ---------------------------------------------------------

.fxw-w   { flex-wrap: wrap !important; }
.fxw-wr  { flex-wrap: wrap-reverse !important; }
.fxw-nw  { flex-wrap: nowrap !important; }

// ---------------------------------------------------------
// @Flex Direction
// ---------------------------------------------------------

.fxd-c   { flex-direction: column !important; }
.fxd-cr  { flex-direction: column-reverse !important; }
.fxd-r   { flex-direction: row !important; }
.fxd-rr  { flex-direction: row-reverse !important; }

// ---------------------------------------------------------
// @Align Items
// ---------------------------------------------------------

.ai-fs   { align-items: flex-start !important; }
.ai-fe   { align-items: flex-end !important; }
.ai-c    { align-items: center !important; }
.ai-b    { align-items: baseline !important; }
.ai-s    { align-items: stretch !important; }

// ---------------------------------------------------------
// @Align Self
// ---------------------------------------------------------

.as-fs   { align-self: flex-start !important; }
.as-fe   { align-self: flex-end !important; }
.as-c    { align-self: center !important; }
.as-b    { align-self: baseline !important; }
.as-s    { align-self: stretch !important; }

// ---------------------------------------------------------
// @Align Content
// ---------------------------------------------------------

.ac-fs   { align-content: flex-start !important; }
.ac-fe   { align-content: flex-end !important; }
.ac-c    { align-content: center !important; }
.ac-s    { align-content: stretch !important; }
.ac-sb   { align-content: space-between !important; }
.ac-sa   { align-content: space-around !important; }

// ---------------------------------------------------------
// @Justify Content
// ---------------------------------------------------------

.jc-fs   { justify-content: flex-start !important; }
.jc-fe   { justify-content: flex-end !important; }
.jc-c    { justify-content: center !important; }
.jc-sb   { justify-content: space-between !important; }
.jc-sa   { justify-content: space-around !important; }

// ---------------------------------------------------------
// @Flex
// ---------------------------------------------------------

.fx-n    { flex: none !important; }
.fx-1    { flex: 1 !important; }
.fx-a    { flex: auto !important; }

// ---------------------------------------------------------
// @Flex Basis
// ---------------------------------------------------------

.fxb-a   { flex-basis: auto !important; }
.fxb-0   { flex-basis: 0 !important; }

// ---------------------------------------------------------
// @Flex Grow
// ---------------------------------------------------------

.fxg-1   { flex-grow: 1 !important; }
.fxg-0   { flex-grow: 0 !important; }

// ---------------------------------------------------------
// @Flex Shrink
// ---------------------------------------------------------

.fxs-1   { flex-shrink: 1 !important; }
.fxs-0   { flex-shrink: 0 !important; }

// ---------------------------------------------------------
// @Flex Order
// ---------------------------------------------------------

@for $i from 0 through 12 {
  .ord-#{$i} { order: $i !important; }
}

// ---------------------------------------------------------
// @Gap
// ---------------------------------------------------------

@for $i from 10 through 500 {
  @if $i % 10 == 0 {
    .gp-#{$i} { gap: #{$i}px !important; }
  }
}