// ---------------------------------------------------------
// @Fixed Width
// ---------------------------------------------------------

.w-1\/4r, .sz-1\/4r    { width: 0.25rem !important; }
.w-1\/2r, .sz-1\/2r    { width: 0.5rem !important; }
.w-3\/4r, .sz-3\/4r    { width: 0.75rem !important; }
.w-1r, .sz-1r          { width: 1rem !important; }
.w-3\/2r, .sz-3\/2r    { width: 1.5rem !important; }
.w-2r, .sz-2r          { width: 2rem !important; }
.w-5\/2r, .sz-5\/2r    { width: 2.5rem !important; }
.w-3r, .sz-3r          { width: 3rem !important; }
.w-7\/2r, .sz-7\/2r    { width: 3.5rem !important; }
.w-4r, .sz-4r          { width: 4rem !important; }
.w-9\/2r, .sz-9\/2r    { width: 4.5rem !important; }
.w-5r, .sz-5r          { width: 5rem !important; }
.w-11\/2r, .sz-11\/2r  { width: 5.5rem !important; }
.w-6r, .sz-6r          { width: 6rem !important; }

// ---------------------------------------------------------
// @Relative Width
// ---------------------------------------------------------

.w-0     { width: 0px !important; }
.w-10p   { width: 10% !important; }
.w-20p   { width: 20% !important; }
.w-30p   { width: 30% !important; }
.w-40p   { width: 40% !important; }
.w-50p   { width: 50% !important; }
.w-60p   { width: 60% !important; }
.w-70p   { width: 70% !important; }
.w-80p   { width: 80% !important; }
.w-90p   { width: 90% !important; }
.w-100p  { width: 100% !important; }
.w-1px   { width: 1px !important; }
.w-a     { width: auto !important; }

// ---------------------------------------------------------
// @Fixed Height
// ---------------------------------------------------------

.h-1\/4r, .sz-1\/4r    { height: 0.25rem !important; }
.h-1\/2r, .sz-1\/2r    { height: 0.5rem !important; }
.h-3\/4r, .sz-3\/4r    { height: 0.75rem !important; }
.h-1r, .sz-1r          { height: 1rem !important; }
.h-3\/2r, .sz-3\/2r    { height: 1.5rem !important; }
.h-2r, .sz-2r          { height: 2rem !important; }
.h-5\/2r, .sz-5\/2r    { height: 2.5rem !important; }
.h-3r, .sz-3r          { height: 3rem !important; }
.h-7\/2r, .sz-7\/2r    { height: 3.5rem !important; }
.h-4r, .sz-4r          { height: 4rem !important; }
.h-9\/2r, .sz-9\/2r    { height: 4.5rem !important; }
.h-5r, .sz-5r          { height: 5rem !important; }
.h-11\/2r, .sz-11\/2r  { height: 5.5rem !important; }
.h-6r, .sz-6r          { height: 6rem !important; }

.h-0     { height: 0 !important; }
.h-auto  { height: auto !important; }
.h-100p  { height: 100% !important; }
.h-100vh { height: 100vh !important; }

// ---------------------------------------------------------
// @Max Size
// ---------------------------------------------------------

.mw-100p { max-width: 100% !important; }
.mh-100p { max-height: 100% !important; }

// ---------------------------------------------------------
// @Min height
// ---------------------------------------------------------

@for $i from 10 through 500 {
  @if $i % 10 == 0 {
    .mH-#{$i} { min-height: #{$i}px !important; }
  }
}

// ---------------------------------------------------------
// @Min width
// ---------------------------------------------------------

@for $i from 10 through 500 {
  @if $i % 10 == 0 {
    .mW-#{$i} { min-width: #{$i}px !important; }
  }
}