@import 'assets/styles/index.scss';

.Badge {
  display: flex;
  font-size: inherit;
}

.Badge--default .ant-badge-count {
  color: $default-white !important;
  background: $badge-todo !important;
  box-shadow: 0 0 0 1px lighten($badge-todo, 10%) !important;
}

.Badge--disabled .ant-badge-count {
  color: #2c3f4c !important;
  background: $badge-closed !important;
  box-shadow: 0 0 0 1px lighten($badge-closed, 10%) !important;
}

.Badge--success .ant-badge-count {
  color: $default-white !important;
  background: lighten($badge-resolved, 5%) !important;
  box-shadow: 0 0 0 1px lighten($badge-resolved, 10%) !important;
}

.Badge--transparent .ant-badge-count {
  background: transparent !important;
  box-shadow: none !important;
}

.Badge--warning .ant-badge-count {
  color: $default-dark !important;
  background: lighten($badge-progress, 10%) !important;
  box-shadow: 0 0 0 1px lighten($badge-progress, 10%) !important;
}

.Badge--danger .ant-badge-count {
  color: $default-white !important;
  background: lighten($badge-problem, 5%) !important;
  box-shadow: 0 0 0 1px lighten($badge-problem, 10%) !important;
}

.Badge--large .ant-badge-count {
  font-size: 15px;
  padding: 0px 15px;
}

.Badge--small .ant-badge-count {
  font-size: 10px;
  padding: 0px 8px;
}

.Badge .ant-badge {
  color: inherit !important;
}

.Badge--fullwidth {
  width: 100% !important;
  .ant-badge {
    width: 100% !important;
  }
}