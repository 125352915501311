// ---------------------------------------------------------
// @Margin (0 > 4 Step 1)
// ---------------------------------------------------------

@for $i from 0 through 4 {
  .m-#{$i}  { margin: #{$i}px !important; }
  .mT-#{$i} { margin-top: #{$i}px !important; }
  .mR-#{$i} { margin-right: #{$i}px !important; }
  .mB-#{$i} { margin-bottom: #{$i}px !important; }
  .mL-#{$i} { margin-left: #{$i}px !important; }

  .mY-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }

  .mX-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }

  .m-nv-#{$i}  { margin: -#{$i}px !important; }
  .mT-nv-#{$i} { margin-top: -#{$i}px !important; }
  .mR-nv-#{$i} { margin-right: -#{$i}px !important; }
  .mB-nv-#{$i} { margin-bottom:- #{$i}px !important; }
  .mL-nv-#{$i} { margin-left: -#{$i}px !important; }

  .mY-nv-#{$i} {
    margin-top: -#{$i}px !important;
    margin-bottom: -#{$i}px !important;
  }

  .mX-nv-#{$i} {
    margin-left: -#{$i}px !important;
    margin-right: -#{$i}px !important;
  }
}

// ---------------------------------------------------------
// @Margin (5 > 35 Step 5)
// ---------------------------------------------------------

@for $i from 5 through 35 {
  @if $i % 5 == 0 {
    .m-#{$i}  { margin: #{$i}px !important; }
    .mT-#{$i} { margin-top: #{$i}px !important; }
    .mR-#{$i} { margin-right: #{$i}px !important; }
    .mB-#{$i} { margin-bottom: #{$i}px !important; }
    .mL-#{$i} { margin-left: #{$i}px !important; }

    .mY-#{$i} {
      margin-top: #{$i}px !important;
      margin-bottom: #{$i}px !important;
    }

    .mX-#{$i} {
      margin-left: #{$i}px !important;
      margin-right: #{$i}px !important;
    }

    .m-nv-#{$i}  { margin: -#{$i}px !important; }
    .mT-nv-#{$i} { margin-top: -#{$i}px !important; }
    .mR-nv-#{$i} { margin-right: -#{$i}px !important; }
    .mB-nv-#{$i} { margin-bottom: -#{$i}px !important; }
    .mL-nv-#{$i} { margin-left: -#{$i}px !important; }

    .mY-nv-#{$i} {
      margin-top: -#{$i}px !important;
      margin-bottom: -#{$i}px !important;
    }

    .mX-nv-#{$i} {
      margin-left: -#{$i}px !important;
      margin-right: -#{$i}px !important;
    }
  }
}

// ---------------------------------------------------------
// @Margin (40 > 160 Step 10)
// ---------------------------------------------------------

@for $i from 40 through 160 {
  @if $i % 10 == 0 {

    .m-#{$i}  { margin: #{$i}px !important; }
    .mT-#{$i} { margin-top: #{$i}px !important; }
    .mR-#{$i} { margin-right: #{$i}px !important; }
    .mB-#{$i} { margin-bottom: #{$i}px !important; }
    .mL-#{$i} { margin-left: #{$i}px !important; }

    .mY-#{$i} {
      margin-top: #{$i}px !important;
      margin-bottom: #{$i}px !important;
    }

    .mX-#{$i} {
      margin-left: #{$i}px !important;
      margin-right: #{$i}px !important;
    }

    .m-nv-#{$i}  { margin: -#{$i}px !important; }
    .mT-nv-#{$i} { margin-top: -#{$i}px !important; }
    .mR-nv-#{$i} { margin-right: -#{$i}px !important; }
    .mB-nv-#{$i} { margin-bottom: -#{$i}px !important; }
    .mL-nv-#{$i} { margin-left: -#{$i}px !important; }

    .mY-nv-#{$i} {
      margin-top: -#{$i}px !important;
      margin-bottom: -#{$i}px !important;
    }

    .mX-nv-#{$i} {
      margin-left: -#{$i}px !important;
      margin-right: -#{$i}px !important;
    }
  }
}

// ---------------------------------------------------------
// @Margin Auto
// ---------------------------------------------------------

.m-a  { margin: auto !important; }
.mX-a { margin-left: auto !important; margin-right: auto !important; }
.mT-a { margin-top: auto !important; }
.mR-a { margin-right: auto !important; }
.mB-a { margin-bottom: auto !important; }
.mL-a { margin-left: auto !important; }