@import 'assets/styles/index.scss';

.Upload {
  display: flex;
}

.Upload .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
  margin-top: 10px;
}

.Upload .ant-upload-select-picture {
  display: inline-flex;
}

.Upload .ant-upload-list-picture {
  flex: 100%;
}

.Upload .ant-upload-list-item-thumbnail {
  display: flex !important;
  align-items: center;
}

.Upload [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}