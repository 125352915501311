@import 'assets/styles/index.scss';

.ClauseField {
  cursor: pointer;

  .ClauseField-Button, .ant-btn[disabled] {
    padding: 4px 7px;
    width: 32px;
  }

  .ClauseField-DragMenu {
    cursor: grab;
    color: $default-grey;
  }

  .ClauseField-Option {
    width: 100%;
  }

  .ClauseField-OptionText {
    overflow: "hidden";
    white-space: "nowrap";
    text-overflow: "ellipsis";
  }
}
