@import 'assets/styles/index.scss';

.ant-table-expanded-row .ant-table-content {
  background-color: $grey-100;
  padding-bottom: 20px;
}

.ant-table-expanded-row .ant-table-tbody {
  background-color: $default-white;
  padding-bottom: 20px;
}

.ant-table-expanded-row .ant-table-tbody tr td {
  vertical-align: baseline;
}
