@import 'assets/styles/index.scss';

.Address-field-container {
  margin: -7.5px;
}

.Address-field {
  display: inline-block;
  width: calc(100% / 2);
  padding: 7.5px;

  .ant-row {
    margin: 0;
  }

  &:nth-child(n+5) {
    width: calc(100% / 3);
  }

  @include to($breakpoint-md) {
    width: 50%;

    &:nth-child(n+5) {
      width: 50%;
    }

    &:last-child:nth-child(odd) {
      width: 100%;
    }
  }
  
  @include to($breakpoint-sm) {
    width: 100% !important;
  }
}