@import 'assets/styles/index.scss';

.SpaceDefinitionField {
  cursor: pointer;

  .ant-table-cell-with-append {
    display: flex;

    .SpaceDefinitionField-Name {
      height: 30px;
      margin: 5px;
    }
  }

  td .ant-table-row-expand-icon {
    padding: 7px !important;
    margin-top: 10px !important;
  }
}