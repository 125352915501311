@import 'assets/styles/index.scss';

.Form-Group {
  &.ant-collapse {
    background-color: $default-white;
    border: 1px solid $tab-grey;
    margin-bottom: 14px;
  }

  .ant-collapse-header {
    color: $default-text-color !important;
    font-size: 18px;
    position: relative;

    .anticon {
      color: $default-text-color !important;
    }
  }

  .ant-collapse-content {
    border-top: none;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: calc(100% - 32px);
      position: absolute;
      top: 0;
      left: 16px;
      height: 0px;
      background-color: $tab-grey;
    }
  }

  .ant-row {
    margin-bottom: 10px;
  }
}

.Form-Group--open {
  .ant-collapse-content {
    &::before {
      content: '';
      display: block;
      width: calc(100% - 32px);
      position: absolute;
      top: 0;
      left: 16px;
      height: 1px;
      background-color: $tab-grey;
    }
  }

  .ant-collapse-content {
    border-top: none;
  }
}

.Form-Group--has-warning {
  .ant-collapse-header {
    color: $default-warning !important;
  }
}

.Form-Group--has-error {
  .ant-collapse-header {
    color: $default-danger !important;
  }
}

.Form-Grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 15px;

  &-Item {
    &--one-col {
      grid-column: span 1;
    }

    &--two-col {
      grid-column: span 2;
    }

    &--three-col {
      grid-column: span 3;
    }

    &--four-col {
      grid-column: span 4;
    }

    &--five-col {
      grid-column: span 5;
    }

    &--six-col {
      grid-column: span 6;
    }

    &--seven-col {
      grid-column: span 7;
    }

    &--eight-col {
      grid-column: span 8;
    }

    &--nine-col {
      grid-column: span 9;
    }

    &--ten-col {
      grid-column: span 10;
    }

    &--eleven-col {
      grid-column: span 11;
    }

    &--twelve-col {
      grid-column: 1/-1;
    }
  }
}

@include to($breakpoint-xl) {
  .Form-Grid {
    &-Item {
      &--one-col {
        grid-column: span 2;
      }

      &--two-col {
        grid-column: span 3;
      }

      &--ten-col,
      &--eleven-col {
        grid-column: 1/-1;
      }
    }
  }
}

@include to($breakpoint-md) {
  .Form-Grid {
    &-Item {
      &--one-col,
      &--two-col,
      &--three-col {
        grid-column: span 4;
      }

      &--four-col, &--three-col, &--five-col {
        grid-column: span 6;
      }

      &--seven-col,
      &--eight-col,
      &--nine-col {
        grid-column: 1/-1;
      }
    }
  }
}

@include to($breakpoint-sm) {
  .Form-Grid {
    &-Item {
      &--one-col,
      &--two-col,
      &--three-col,
      &--four-col,
      &--five-col,
      &--six-col {
        grid-column: 1/-1;
      }
    }
  }
}
