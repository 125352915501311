@import 'assets/styles/index.scss';

.Radio-Field {
  .ant-select-selector {
    width: 100%;
  }

  &--has-warning {
    &.ant-radio-wrapper-checked {
      .ant-radio-checked {
        .ant-radio-inner {
          border-color: $default-warning !important;

          &:after {
            background-color: $default-warning !important;
          }

          &:focus {
            box-shadow: 0 0 0 2px rgba($default-warning, 0.2) !important;
          }
        }
      }
    }
  }

  &--has-error {
    &.ant-radio-wrapper-checked {
      .ant-radio-checked {
        .ant-radio-inner {
          border-color: $default-danger !important;

          &:after {
            background-color: $default-danger !important;
          }

          &:focus {
            box-shadow: 0 0 0 2px rgba($default-danger, 0.2) !important;
          }
        }
      }
    }
  }
}