@import 'assets/styles/index.scss';

.InsightFormula {

  .mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    padding: 9px;
    min-height: 63px;
  }

  .mentions--multiLine .mentions__input {
    padding: 9px;
    background-color: $default-white !important;
    border: 1px solid $ant-border-color;
  }

  .mentions__suggestions__list {
    max-height: 200px;
    overflow-y: auto;
    background-color: $default-white;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  .mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid $ant-border-color;
  }

  .mentions__suggestions__item--focused {
    background-color: $grey-200;
  }

  .mentions__mention {
    position: relative;
    z-index: 1;
    background-color: $grey-300 !important;
    color: $ant-text-color;
  }
}