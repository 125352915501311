@import 'assets/styles/index.scss';

.TimeRangePicker {
  display: inline-flex;
  width: 100%;
  min-width: 150px;
  height: 32px;
}

.TimeRangePicker-Popup {
  .ant-picker-footer {
    display: none;
  }
}