@import "assets/styles/index.scss";

.DynamicFieldComponentList .ant-tree-treenode {
  align-items: center !important;
}

.DynamicFieldComponentList .ant-tree-switcher {
  align-self: center !important;
}

.DynamicFieldComponentList .ant-tree-draggable-icon {
  cursor: grab;
}
