@import 'assets/styles/index.scss';

.Timeline {
  background-color: $default-white;
  border: 1px solid $border-color ;
  border-radius: 2px;
  color: $ant-text-color;
  margin-top: 20px;
  padding: 20px;
  width: 100%;
}

.Timeline--primary .ant-timeline-item-head {
  color: $default-primary !important;
  border-color: $default-primary !important;
}

.Timeline--success .ant-timeline-item-head {
  color: $inverse-success !important;
  border-color: $inverse-success !important;
}

.Timeline--warning .ant-timeline-item-head {
  color: $inverse-danger !important;
  border-color: $inverse-danger !important;
}

.Timeline--default .ant-timeline-item-head {
  color: $inverse-dark !important;
  border-color: $inverse-dark !important;
}