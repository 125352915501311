@import 'assets/styles/index.scss';

$border-color: $tab-grey;

.InfoBox {
  border: 1px solid $tab-grey;
  background-color: $default-white;
  padding: 16px;

  &-Section {
    &--with-border {
      border-bottom: 1px solid rgba($tab-grey, 0.75);
    }
  }
}