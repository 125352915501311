// ---------------------------------------------------------
// @Quick Border Helpers
// ---------------------------------------------------------

$border-width: 1px;

.bd  { border: #{$border-width} solid #{$border-color} !important; }
.bdT { border-top: #{$border-width} solid #{$border-color} !important; }
.bdR { border-right: #{$border-width} solid #{$border-color} !important; }
.bdB { border-bottom: #{$border-width} solid #{$border-color} !important; }
.bdL { border-left: #{$border-width} solid #{$border-color} !important; }

// ---------------------------------------------------------
// @Border Width
// ---------------------------------------------------------

@for $i from 0 through 5 {
  .bdw-#{$i}  { border-width: #{$i}px !important; }
  .bdwT-#{$i} { border-top-width: #{$i}px !important; }
  .bdwR-#{$i} { border-right-width: #{$i}px !important; }
  .bdwB-#{$i} { border-bottom-width: #{$i}px !important; }
  .bdwL-#{$i} { border-left-width: #{$i}px !important; }
}

// ---------------------------------------------------------
// @Border Radius
// ---------------------------------------------------------

@for $i from 0 to 5 {
  .bdrs-#{$i} { border-radius: #{$i}px !important; }

  .bdrsT-#{$i} {
    border-top-left-radius: #{$i}px !important;
    border-top-right-radius: #{$i}px !important;
  }

  .bdrsR-#{$i} {
    border-top-right-radius: #{$i}px !important;
    border-bottom-right-radius: #{$i}px !important;
  }

  .bdrsB-#{$i} {
    border-bottom-left-radius: #{$i}px !important;
    border-bottom-right-radius: #{$i}px !important;
  }

  .bdrsL-#{$i} {
    border-top-left-radius: #{$i}px !important;
    border-bottom-left-radius: #{$i}px !important;
  }
}

.bdrs-50p  { border-radius: 50% !important; }
.bdrs-10em { border-radius: 10em !important; }

// ---------------------------------------------------------
// @Border Style
// ---------------------------------------------------------

.bds-n  { border-style: none !important; }
.bds-s  { border-style: solid !important; }
.bds-dt { border-style: dotted !important; }
.bds-ds { border-style: dashed !important; }
.bds-db { border-style: double !important; }
.bds-g  { border-style: groove !important; }
.bds-r  { border-style: ridge !important; }
.bds-i  { border-style: inset !important; }
.bds-o  { border-style: outset !important; }
