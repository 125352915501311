@import 'assets/styles/index.scss';

.InsightTable .ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset !important;
}

.InsightTable .ant-table {
  background: transparent !important;
}

.InsightTable .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent !important;
}
