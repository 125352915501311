@import 'assets/styles/index.scss';

.Progress {
  font-size: inherit;
}

.Progress.ant-progress {
  .ant-progress-inner {
    background-color: $grey-400;
  }
  .ant-progress-text {
    color: $ant-text-color;
  }
}