// ---------------------------------------------------------
// @Background Size
// ---------------------------------------------------------

.bgsz-cv   { background-size: cover !important; }
.bgsz-ct   { background-size: contain !important; }
.bgsz-full { background-size: 100% 100% !important; }

// ---------------------------------------------------------
// @Background Position
// ---------------------------------------------------------

.bgpX-c    { background-position-x: center !important; }
.bgpX-t    { background-position-x: top !important; }
.bgpX-r    { background-position-x: right !important; }
.bgpX-l    { background-position-x: left !important; }
.bgpX-b    { background-position-x: bottom !important; }
.bgpY-c    { background-position-y: center !important; }
.bgpY-t    { background-position-y: top !important; }
.bgpY-r    { background-position-y: right !important; }
.bgpY-l    { background-position-y: left !important; }
.bgpY-b    { background-position-y: bottom !important; }

// ---------------------------------------------------------
// @Background Repeat
// ---------------------------------------------------------

.bgr-n     { background-repeat: no-repeat !important; }
.bgr-y     { background-repeat: repeat-y !important; }
.bgr-x     { background-repeat: repeat-x !important; }

// ---------------------------------------------------------
// @Object Fit
// ---------------------------------------------------------

.of-ct     { object-fit: contain !important; }
.of-cv     { object-fit: cover !important; }
.of-f      { object-fit: fill !important; }
.of-n      { object-fit: none !important; }
.of-sd     { object-fit: scale-down !important; }

// ---------------------------------------------------------
// @Resize
// ---------------------------------------------------------

.rsz-v     { resize: vertical !important; }
.rsz-h     { resize: horizontal !important; }

// ---------------------------------------------------------
// @Opacity
// ---------------------------------------------------------

.op-0      { opacity: 0 !important; }
.op-10p    { opacity: 0.1 !important; }
.op-20p    { opacity: 0.2 !important; }
.op-30p    { opacity: 0.3 !important; }
.op-40p    { opacity: 0.4 !important; }
.op-50p    { opacity: 0.5 !important; }
.op-60p    { opacity: 0.6 !important; }
.op-70p    { opacity: 0.7 !important; }
.op-80p    { opacity: 0.8 !important; }
.op-90p    { opacity: 0.9 !important; }
.op-100p   { opacity: 1 !important; }

// ---------------------------------------------------------
// @Cursor
// ---------------------------------------------------------

.cur-na    { cursor: not-allowed !important; }
.cur-p     { cursor: pointer !important; }
.cur-a     { cursor: auto !important; }
.cur-g     { cursor: grab !important; }

// ---------------------------------------------------------
// @User Select
// ---------------------------------------------------------

.us-n      { user-select: none !important; }

// ---------------------------------------------------------
// @Visibility
// ---------------------------------------------------------

.vis-v     { visibility: visible !important; }
.vis-h     { visibility: hidden !important; }
