@import 'assets/styles/index.scss';

.Workflow {
  display: flex;
  background-color: transparent;
  padding: 0;
  margin: 1em auto;
  border-radius: .25em;
  user-select: none;
}

.Workflow::after {
  content: "";
  display: table;
  clear: both;
}

.Workflow__step {
  display: flex;
  flex: 1;
  position: relative;
  padding: 0;
  margin: 4px 4px 4px 0;
}

.Workflow__step > * {
  display: inline-block;
}

.Workflow__step::after {
  /* this is the separator between items */
  display: inline-block;
  color: #959fa5;
}

.Workflow .Workflow__step--is-last {
  margin-right: 0;
}

.Workflow .Workflow__step--is-last::after {
  /* hide separator after the last item */
  display: none;
}

.Workflow__step > * {
  position: relative;
  flex: 1;
  display: inline-flex;
  align-items: center;
  padding: 1em .8em 1em 2.5em;
  color: #2c3f4c;
  background-color: #edeff0;
  border-color: #edeff0;
}

.Workflow__step--color-todo > * {
  color: #fff;
  background-color: $badge-todo;
  border-color: $badge-todo;
}

.Workflow__step--color-progress > * {
  color: #2c3f4c;
  background-color: $badge-progress;
  border-color: $badge-progress;
}

.Workflow__step--color-resolved > * {
  color: #fff;
  background-color: $badge-resolved;
  border-color: $badge-resolved;
}

.Workflow__step--color-problem > * {
  color: #fff;
  background-color: $badge-problem;
  border-color: $badge-problem;
}

.Workflow__step--color-closed > * {
  color: #fff;
  background-color: $badge-closed;
  border-color: $badge-closed;
}

.Workflow .Workflow__step--is-first > * {
  padding-left: 1.6em;
  border-radius: .25em 0 0 .25em;
}

.Workflow .Workflow__step--is-last > * {
  padding-right: 1.6em;
  border-radius: 0 .25em .25em 0;
}

.Workflow__step::after, .Workflow__step > *::after {
  position: absolute;
  content: "";
  top: 0;
  left: 100%;
  height: 100%;
  border: 24px solid transparent;
}

.Workflow__step::after {
  z-index: 1;
  -webkit-transform: translateX(4px);
  -moz-transform: translateX(4px);
  -ms-transform: translateX(4px);
  -o-transform: translateX(4px);
  transform: translateX(4px);
  border-left-color: #fff;
  margin: 0;
}

.Workflow__step > *::after {
  z-index: 2;
  border-left-color: inherit;
}

.Workflow .Workflow__step--is-last::after, .Workflow .Workflow__step--is-last > *::after {
  /* hide the after the last step */
  display: none;
}