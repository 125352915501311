@import 'assets/styles/index.scss';

.TermsModal table {
	width: 100%;
}

.TermsModal table th {
	width: 25%;
}

.TermsModal table td {
	padding: 10px 0px;
}

.TermsModal ul li {
	padding: 10px 0px;
}