@import 'assets/styles/index.scss';

.Sidebar {
  display: flex;
  background-color: $default-white;
  overflow: hidden;
  user-select: none;
  height: 100vh;
}

.Sidebar-primary {
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  width: $primary-sidebar-width;
  border-right: 1px solid $border-color;
}

.Sidebar-primary-header {
  height: 200px;
}

.Sidebar-primary-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Sidebar-primary-nav {
  width: 50px;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 15px;
  color: $default-primary;
}

.Sidebar-primary-logo {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}

.Sidebar-primary-logo img {
  max-height: 60px;
  max-width: 180px;
  width: auto;
  height: auto;
}

.Sidebar-primary-menu {
  display: block;
  overflow: auto;
}

.Sidebar-primary-menu .ant-menu-sub.ant-menu-inline {
  background: inherit !important;
}

.Sidebar-primary-menu .Sidebar-Primary-submenu--no-modules .ant-menu-submenu-arrow {
  display: none !important;
}

.Sidebar-primary-menu-item:nth-of-type(1) {
  border-top: 1px solid $border-color;
}

.Sidebar-primary-menu-item {
  text-align: center;
  height: 50px;
  border-bottom: 1px solid $border-color;
  color: rgba(0, 0, 0, 0.25) !important;
}

.Sidebar-primary.is-collapsed {
  transition: all 0.2s ease;
  width: $primary-sidebar-collapsed-width;
}

.Sidebar-primary-item-is-active {
  color: $default-primary !important;
  font-weight: 500;
}

.Sidebar-primary-footer {
  flex: 2;
  display: flex;
  align-items: flex-end;
  margin: 0 auto;
  padding-bottom: 20px;
}

.Sidebar-secondary {
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  width: $secondary-sidebar-width;
  border-right: 1px solid $border-color;
}

.Sidebar-secondary-header {
  height: 200px;
  margin-left: auto;
}

.Sidebar-secondary-nav {
  height: 50px;
  width: 50px;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 13px;
  color: $default-primary;
}

.Sidebar-secondary-menu {
  font-weight: 500;
}

.Sidebar-secondary-menu-item {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 40px;
  border-bottom: 1px solid $border-color;
}

.Sidebar-secondary-menu-item-is-indented {
  padding-left: 60px;
}

.Sidebar-secondary-menu-item-disabled {
  cursor: not-allowed;
  background-color: rgba($grey-100, 15%);
}

.Sidebar-secondary.is-collapsed {
  transition: all 0.2s ease;
  width: $secondary-sidebar-collapsed-width;
}

.Sidebar-secondary-item-is-active {
  color: $default-primary !important;
  font-weight: 500;
}

.Sidebar-secondary-item-is-disabled {
  color: rgba($grey-500, 30%) !important;
} 