@import 'assets/styles/index.scss';

.Help-Article-Wrapper {
  display: flex;
  flex-direction: column;
  user-select: none;
}

.Help-Article-Sidebar {
  display: flex;
}

.Help-Article-Sidebar-Item {
  display: flex;
}

.Help-Article-Sidebar-Item:hover {
  background-color: $grey-300;
}

.Help-Article-Article-Item {
  display: flex;
  cursor: pointer;
  outline: 1px solid transparent;
  box-shadow: 0 .1rem .6rem -.1rem rgba(16, 24, 40, .1), 0 .2rem .1rem -.2rem rgba(16, 24, 40, .1);
}

.Help-Article-Article-Item:hover {
  box-shadow: 0 13px 37px 0 rgba(0, 0, 0, .21);
  outline: 3px solid $default-primary;
  color: inherit;
}

.Help-Article-Extra-Item {
  width: 33%;
  min-height: 300px;
  border: 1px solid $default-primary;
  border-radius: 10px;
  transition: 0.3s;
}

.Help-Article-Status {
  position: relative;
  min-width: 120px;
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px solid $border-color;
  white-space: nowrap
}

.Help-Article-Status-Badge {
  width: 100px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Help-Article-Status-Ribbon {
  --f: .5em;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.8;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  font-size: 12px;
  font-weight: bold;
  color: $default-white;
  border-image: conic-gradient(#0008 0 0) 51%/var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),100% 100%,calc(100% - var(--f)) calc(100% - var(--f)),var(--f) calc(100% - var(--f)), 0 100%,0 calc(100% - var(--f)),999px calc(100% - var(--f) - 999px),calc(100% - 999px) calc(100% - var(--f) - 999px));
  transform: translate(calc((cos(45deg) - 1)*100%), -100%) rotate(-45deg);
  transform-origin: 100% 100%;
  background-color: $default-warning;
}

.Help-Article-Attachments {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(15, 23, 42, 0.1) 0px 1px 3px 0px, rgba(15, 23, 42, 0.1) 0px 1px 2px -1px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 1rem;
}

.Help-Article-Extra-Item:hover {
  cursor: pointer;
  box-shadow: $default-primary 0px 0px 0px 0.2rem, rgba(0, 0, 0, 0.11) 0px 0.6rem 1.2rem 0px;
  outline: 3px solid $default-primary;
  color: inherit;
}

.Help-Article-Create-Button:hover {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.Help-Article-Article-Upload {
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  flex: 1;
  max-height: 200px;
}

.Help-Article-Article-Upload > span:first-of-type {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Help-Article-Draggable-list {
  user-select: none;
  background-color: white;
  margin: 20px !important;
  padding: 20px !important;
  box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in-out;
}

.Help-Article-Draggable-list:hover {
  transform: scale(1.01);
}

.Help-Article-Draggable-list-item {
  user-select: none;
  padding-left: 5px;
  transition: all 0.2s ease-in-out;
}

.Help-Article-Draggable-list-item:hover {
  padding-left: 5px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
  transform: scale(1.01);
}

.Help-Article-Draggable-list-icon {
  margin-right: 5px;
  opacity: 0.3;
  cursor: grab;
}

.Help-Article-Draggable-list-line {
  border-bottom: dashed 2px rgba(245, 75, 53, 0.445) !important;
}
