@import 'assets/styles/index.scss';

.Phone-Field-Select {
  display: inline-block;
  padding-right: 5px;
  width: calc(100% / 3);
}

.Phone-Field-Text {
  display: inline-block;
  width: calc((100% / 3) * 2) ;
}

@include to($breakpoint-sm) {
  .Phone-Field-Select {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }

  .Phone-Field-Text {
    width: 100%;
  }
}