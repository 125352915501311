@import 'assets/styles/index.scss';

.TabView-bar {
  padding: 0px 30px;
  margin: 0;
  background-color: #F2F2F2;
}

.TabView-pane {
  padding: 20px 30px 20px 30px;
}

.TabView-bar .ant-tabs-tab {
  user-select: none;
}

.TabView-pane .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  user-select: none;
}

.TabView-bar--transparent {
  background-color: transparent !important;
}