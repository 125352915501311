@import 'assets/styles/index.scss';

.TemplateModal {
  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .TemplateModal--tabs .ant-tabs-nav-list .ant-tabs-tab-disabled {
    user-select: none !important;
    color: rgba(0,0,0,.85) !important;
    border: none !important;
    background: transparent !important;

    .ant-tabs-tab-btn:active {
      color: rgba(0,0,0,.85) !important;
    }
  }
}