@import 'assets/styles/index.scss';

.EnvironmentBanner {
	position: fixed;
	width: 100%;
	height: 20px;
	color: black;
	line-height: 1.4;
	font-weight: 500;
	text-align: center;
	z-index: 11;
}

.Auth-layout:is(.EnvironmentBanner--show),
.Layout:is(.EnvironmentBanner--show) {
	height: calc(100vh - 20px) !important;
	min-height: calc(100vh - 20px) !important;
}

.EnvironmentBanner--development {
	background-color: lighten($badge-closed, 10%) !important;
}

.EnvironmentBanner--staging {
	background-color: lighten($badge-todo, 10%) !important;
}

.EnvironmentBanner--testing {
	background-color: lighten($badge-progress, 10%) !important;
}

.EnvironmentBanner--training {
	background-color: lighten($badge-resolved, 10%) !important;
}
