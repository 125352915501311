html, html a, body {
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-x: none;
}

body {
  font-family: $font-primary;
  font-size: 14px;
  color: $default-text-color;
  line-height: 1.5;
  letter-spacing: 0.2px;
  background-color: $grey-100;
  overscroll-behavior-x: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-secondary;
  letter-spacing: 0.5px;
  line-height: 1.5;

  a {
    font-family: $font-secondary;
  }

  small {
    font-weight: 300;
    color: lighten($default-dark, 5%);
  }
}

p {
  font-family: $font-primary;
  line-height: 1.9;
  margin: 0;
}

.lead {
  font-size: 18px;
}

ul {
  margin-bottom: 0;
}

a {
  color: $default-text-color;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

}

:focus {
  outline: none;
}

hr {
  border-top: 1px solid $border-color;
}