@import 'assets/styles/index.scss';

.Carousel-container {

  .Carousel-prev-arrow {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
    width: max-content;
  }

  .Carousel-next-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;
    width: max-content;
  }

  .ant-carousel, .slick-list, .slick-track, .slick-slide, .slick-slide > div{
    min-height: 350px !important;
    height: 100% !important;
  }

  .slick-dots {
    bottom: 30px;
  }

  .slick-dots li button {
    background: $default-grey !important;
  }

  .slick-dots li.slick-active button {
    background: $grey-600 !important;
  }
}

.Carousel-navigation-button {
  border: 0 !important;
  box-shadow: unset !important;
}
