@import 'assets/styles/index.scss';

.Slider-Field {
  .ant-slider-mark-text {
    width: 18%;
  }
}

.Slider-Field.is-modified .ant-slider-handle {
  border-color: $default-warning !important;
}