@import 'assets/styles/index.scss';

.Notifications {
  .Notifications-Option {
    width: 100%;
  }

  .Notifications-OptionText {
    overflow: "hidden";
    white-space: "nowrap";
    text-overflow: "ellipsis";
  }
}
