@import 'assets/styles/index.scss';

.Geo-location-field {
  display: flex;

  &:last-child {
    padding-right: 0;
  }
}

.Geo-location-field .Field .ant-input-number-input {
  text-align: right;
  padding-right: 30px;
}

@include to($breakpoint-sm) {
  .Geo-location-field {
    width: 100%;
  }
}
