@import 'assets/styles/index.scss';

.Header {
  background-color: $default-white;
  border-bottom: 1px solid $border-color;
  transition: all 0.2s ease;
  user-select: none;
}

.Header-wrapper {
  display: flex;
  height: $header-height;
  align-items: center;
  padding: 20px 30px;
}

.Header-breadcrumbs {
  min-width: 0;
}

.Header-breadcrumbs .ant-breadcrumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Header-profile {
  display: inline-flex;
  padding-left: 30px;
}

.Header-profile-name {
  display: inline-flex;
  align-items: center;
}

.Header-profile-name--is-masquerading {
  position: relative;
  min-width: 120px;
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px solid $border-color;
  white-space: nowrap
}

.Header-profile-badge {
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Header-icons {
  display: inline-flex;
  border-right: 1px solid $border-color;
  padding-right: 10px;
}

.Header-icon {
  display: inline-flex;
  align-items: center;
  color: $grey-700;
  padding-right: 20px;

  .ant-badge {
    color: $grey-700;
  }
}

.Header-icon:hover .ant-badge {
  color: $blue;
}

.Header-icon-active, .Header-icon-active .ant-badge {
  color: $blue !important;
}

.Header-icon-badge {
  position: absolute;
  display: inline-block;
  background-color: $red;
  top: -1px;
  left: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.Header-profile-skeleton {
  padding-right: 20px;

  .ant-skeleton-title {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}