@import 'assets/styles/index.scss';

.ActivityManagementField {
  cursor: pointer;

  & .DragMenu {
    cursor: grab;
    color: $default-grey;
  }

  & thead.ant-table-thead > tr > th {
    font-weight: 400;
  }

  & .DisabledViewButton {
    cursor: not-allowed;
    & button[disabled] {
      pointer-events: none;
    }
  }
}

.CommentsContainer {
  height: 500px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(to left, #999, #888);
    --webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to left, #888, #777);
  }
}

.GanttTaskTable {
  & thead.ant-table-thead > tr > th, & tbody.ant-table-tbody > tr > td {
    padding-top: 0;
    padding-bottom: 0;
  }
}