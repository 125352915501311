.link {
  color: $default-text-color;
  cursor: pointer;

  &:hover {
    color: $blue;
    border-color: $blue;
  }

  &.active {
   color: $blue;
  }
}

.star {
  color: $default-text-color;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: rgb(255, 171, 0) !important;
  }

  &.active {
    color: rgb(255, 171, 0) !important;
  }
}

.disabled {
  cursor: not-allowed !important;
}

.text-blur {
  text-shadow: 0 0 8px #000;
	color: rgba(255,255,255,0);
  user-select: none;
}
