@import 'assets/styles/index.scss';

.Dynamic-Field {
  grid-column: 1/-1;
}

.Dynamic-Field-Panel .ant-collapse-header {
  cursor: default !important;
}

.Dynamic-Field-Select.ant-select-disabled .ant-select-selector input {
  cursor: pointer !important;
}

.Dynamic-Field-Select.ant-select-disabled.ant-select-dropdown {
  background-color: #f5f5f5 !important;
}

.Dynamic-Field-Select.ant-select-disabled.ant-select-dropdown .ant-select-item-option {
  background-color: inherit !important;
  cursor: not-allowed !important;
}
