@import "assets/styles/index.scss";

.MetricsTable .ant-table-content {
  padding: 0 !important;
}

.MetricsTable thead.ant-table-thead {
  height: 50px;
}

.MetricsTable thead.ant-table-thead > tr > th {
  color: $default-dark;
  font-weight: 600;
}

.MetricLevelsTable tr.ant-table-row > td {
  vertical-align: top !important;
}

.Editor {

  background: $default-white;

  .ql-toolbar {
    border: none;
    border-bottom: 1px solid $ant-border-color;
  }

  .ql-container {
    border: none;
  }

  &--read-only, .ql-container, .ql-editor {
    min-height: 80px;
    max-height: 300px;
    overflow-y: auto;
  }

  .ql-picker.ql-header {
    .ql-picker-item[data-value="1"]::before {
      @extend .fsz-lg;
      @extend .fw-500;
    }

    .ql-picker-item[data-value="2"]::before {
      @extend .fsz-md;
      @extend .fw-500;
    }

    .ql-picker-item[data-value="3"]::before {
      @extend .fsz-sm;
      @extend .fw-500;
    }

    .ql-picker-item[data-value="4"]::before {
      font-size: 14px;
    }
  }

  &--read-only, .ql-editor {
    h1 {
      @extend .fsz-lg;
      @extend .fw-500;
    }

    h2 {
      @extend .fsz-md;
      @extend .fw-500;
    }

    h3 {
      @extend .fsz-sm;
      @extend .fw-500;
    }

    p {
      font-size: 14px;
    }
  }
}

.Editor--disabled .ql-toolbar {
  display: none !important;
}

.Editor--disabled {
  background: #f5f5f5 !important;
  min-height: 80px;
}

.Editor ins {
  background: lightgreen;
  text-decoration: none;
}

.Editor del {
  background: pink;
}

.Metric-Card {
  width: calc(100% / 3);
  padding: 7.5px;

  @include to($breakpoint-xxl) {
    width: calc(100% / 2) !important;
  }

  @include to($breakpoint-sm) {
    width: 100% !important;
  }

  .Metric-Card-Description {
    height: 150px;
    overflow-y: auto;
    background: #f5f5f5 !important;
  }
}

.MetricModalTransfer .ant-transfer-list-header .ant-transfer-list-checkbox,
.MetricModalTransfer .ant-transfer-list-header .ant-transfer-list-header-dropdown,
.MetricModalTransfer .ant-transfer-list-header .ant-transfer-list-header-selected {
  display: none;
}

.MetricModalTransfer .ant-transfer-list-header .ant-transfer-list-header-title {
  text-align: left;
}
