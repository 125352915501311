@import 'assets/styles/index.scss';

.InsightModalTable .ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset !important;
}

.InsightModalTable .ant-table {
  background: transparent !important;
}

.InsightModalTable .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent !important;
}

.InsightModalJoinsTable .ant-table .ant-table-cell {
  padding-left: 0px !important;
}

.InsightModalTransfer .ant-transfer-list-header .ant-transfer-list-checkbox,
.InsightModalTransfer .ant-transfer-list-header .ant-transfer-list-header-dropdown,
.InsightModalTransfer .ant-transfer-list-header .ant-transfer-list-header-selected {
  display: none;
}

.InsightModalTransfer .ant-transfer-list-header .ant-transfer-list-header-title {
  text-align: left;
}